import { useCallback, useEffect, useRef, useState } from 'react';

function useTimer(interval = 1000) {
    const [tick, setTick] = useState(false);
    const [value, setValue] = useState({ date: new Date() });

    const updateTick = useCallback(() => {
        setTick(true);
    }, []);

    useEffect(() => {
        const timer = setInterval(updateTick, interval);
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if (!tick) return
        setTick(false)
        var e = { ...value }
        const previousDate = e.date
        e.date = new Date()
        e.elapsed = (e.date - previousDate) * 1.0 / 1000
        setValue(e);
        //console.log(e)
    }, [tick]);

    return value;
};

function useTimerWithClear(interval = 1000) {
    const timerRef = useRef();
    const [tick, setTick] = useState(false);
    const [timer, setTimer] = useState({ date: new Date() });

    const updateTick = useCallback(() => {
        setTick(true);
    }, []);

    function clearTimer() {
        clearInterval(timerRef.current);
    }

    useEffect(() => {
        const timerId = setInterval(updateTick, interval);
        timerRef.current = timerId;
        return () => {
            clearInterval(timerId);
        }
    }, []);

    useEffect(() => {
        if (!tick) return
        setTick(false)
        var e = { ...timer }
        const previousDate = e.date
        e.date = new Date()
        e.elapsed = (e.date - previousDate) * 1.0 / 1000
        setTimer(e);
        //console.log(e)
    }, [tick]);

    return [timer, clearTimer];
};

export { useTimer, useTimerWithClear };