import React from 'react'
import { useContext } from 'react';
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import isLogin from '../../calls/authentication/isLogin';
import TopBarComponenent from '../TopBarComponenent';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'
import TimeDisplay from "../TimeDisplay/TimeDisplay"
import { admin } from '../../definitions/roles'

import hasRoleAuthority from '../../calls/administration/roles/hasRoleAuthority';

function Installation(props) {
    const navigate = useNavigate();
    const { setBusy } = useContext(AppContext);
    const { appTheme } = React.useContext(AppContext);

    const { isFullScreen } = React.useContext(AppContext);
    const [ready, setReady] = React.useState(false);

    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "30px";

    const checkIfReady = () => {
        setBusy(true)
        showLoading(true)
        isLogin((e) => {
            if (e.error) {
                navigate("/home", { replace: true });
                setBusy(false)
                showLoading(false)
                return;
            }

            setReady(true);
        })
    }

    const verifyRoleAuthority = (onComplete) => {
        hasRoleAuthority((e) => {
            if (e.error) {
                navigate("/selector", { replace: true });
                setBusy(false)
                showLoading(false)
                return;
            }

            if (onComplete)
                onComplete()
        }, admin)
    }

    React.useEffect(() => {
        checkIfReady();
    }, []);

    React.useEffect(() => {
        if (ready) {
            verifyRoleAuthority(() => {
                setBusy(false)
                showLoading(false)
            })
        }
    }, [ready]);

    if (!ready) {
        return null;
    }

    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
            <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appTheme.themeColor }}>
                <TopBarComponenent
                    showInfo={true}
                    showScreenControl={true}
                    userMenuControlOptions={{
                        userMenuControl: true,
                        showUserMenuDashboard: true
                    }} />
            </div>
            <div style={{ height: "100%", width: "100%", display: "flex", flex: 1, }}>
                <button>
                    Install New Unit
                </button>
            </div>
            <div style={{
                height: barHeight, backgroundColor: appTheme.themeColor, display: 'flex',
                width: "100%",
                justifyContent: "center",
                position: "relative"
            }}>
                {isFullScreen && <TimeDisplay />}
            </div>
        </div>);
}

export default Installation;