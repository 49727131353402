import { isMobile } from 'react-device-detect';
import React from 'react'

// importing datagrid 48 above
//import { DataGrid, Row } from 'react-data-grid';


// importing datagrid below 48
import DataGrid from 'react-data-grid';
import { Row } from 'react-data-grid';

import { AppContext } from "../AppContext";
import { lang } from '../language/lang';
import { getPeriodElapsed, timeSpanFromHours, timeSpanFromDays } from '../utilities/dateHelper';
import { forwardRef, useImperativeHandle } from 'react';
import { buildAssetColorIconUrl } from '../utilities/assetHelper'
import { filterObject } from '../utilities/filterHelper';
import { convertHeadingCompassOrientation } from '../utilities/gpsHelper';
import "./EventsGridComponent.css"
const EventsDataGridRowContext = React.createContext({});

const periods3h = timeSpanFromHours(3);
const periods3d = timeSpanFromDays(3);

const getGridCellClassBasedTimeSent = (timeIndex, withText) => {
    const time = getPeriodElapsed(timeIndex);
    //const sent = timesent ? timesent : undefined;
    if (time < periods3h)
        return withText ? 'chsft_grid_text_green' : 'chsft_grid_green'
    if (time < periods3d)
        return withText ? 'chsft_grid_text_orange' : 'chsft_grid_orange'
    return withText ? 'chsft_grid_text_red' : 'chsft_grid_red'
}


const DefaultFormatter = (props) => {
    const key = props.column.key;
    return (
        <div className={getGridCellClassBasedTimeSent(props.row.timeIndex)} >
            {props.row[key]}
        </div>
    )
}

const DefaultColoredFormatter = (props) => {
    const key = props.column.key;
    return (
        <div className={getGridCellClassBasedTimeSent(props.row.timeIndex, true)} >
            {props.row[key]}
        </div>
    )
}

const timeSentFormatter = ({ row }) => {
    const time = getPeriodElapsed(row.timeIndex);
    const sent = row.timesent ? row.timesent : undefined;
    if (time < periods3h)
        return <span className='chsft_grid_text_green'>{sent}</span>
    if (time < periods3d)
        return <span className='chsft_grid_text_orange'>{sent}</span>
    return <span className='chsft_grid_text_red'>{sent}</span>
}

function AssetFormatter({ row }) {
    const mobile = isMobile;
    const imgSize = mobile ? "10px" : "15px";
    const position = row?.positionData;
    const src = buildAssetColorIconUrl(position?.assetType, position?.assetTypeIconColor);
    return <span className={getGridCellClassBasedTimeSent(row.timeIndex)} style={{
        alignItems: "center", width: "100%", height: "100%", justifyContent: "left", display: "flex"
    }}  >
        <img style={{ margin: "0px 5px", width: imgSize, height: imgSize }} src={src} alt="Logo" />
        {row.asset}
    </span>
}

function EventFormatter(props) {
    const row = props.row
    const { activeUser } = React.useContext(AppContext);
    const eventDesc = activeUser?.debug ? `${row.event} (${row.eventCode})` : row.event;
    return (
        <div className={getGridCellClassBasedTimeSent(row.timeIndex)}>
            {eventDesc}
        </div>
    )
}

function HeadingFormatter({ row }) {
    const { activeUser } = React.useContext(AppContext);
    const headingtDesc = activeUser?.debug ?
        `${convertHeadingCompassOrientation(row.heading)} (${row.heading})` :
        convertHeadingCompassOrientation(row.heading);
    return (
        <div className={getGridCellClassBasedTimeSent(row.timeIndex)}>
            {headingtDesc}
        </div>
    )
}

const getColumns = () => {
    return [
        {
            key: 'date',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.DateHeader,
            renderCell: DefaultColoredFormatter
        },
        {
            key: 'hour',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.HourHeader,
            renderCell: DefaultFormatter
        },
        {
            key: 'asset',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.AssetHeader,
            renderCell: AssetFormatter,
            maxWidth: 550
        },
        {
            key: 'event',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.EventHeader,
            renderCell: EventFormatter,
        },
        {
            key: 'heading',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.HeadingHeader,
            renderCell: HeadingFormatter,
        },
        {
            key: 'speed',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.SpeedHeader,
            renderCell: DefaultFormatter
        },
        {
            key: 'location',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.LocationHeader,
            renderCell: DefaultFormatter,
            maxWidth: 1200
        },
        {
            key: 'state',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.StateHeader,
            renderCell: DefaultFormatter
        },
        {
            key: 'timesent',
            cellClass: 'chsft_rem_cell_pad',
            name: lang.language.TimeSent,
            renderCell: timeSentFormatter,
        },
    ]
};

function rowKeyGetter(row) {
    return row.id;
}

const RowRenderer = (key, props) => {
    return (
        <Row key={key} {...props} selectedCellIdx={undefined}>
        </Row>
    )
};

function EventsGridComponent(props, ref) {
    const { appTheme } = React.useContext(AppContext);
    const { gridSelectedRowId } = React.useContext(AppContext);
    const { setMakerSelectedId } = React.useContext(AppContext);
    const [columns] = React.useState(getColumns);
    const [selectedGridRows, setSelectedGridRows] = React.useState([]);
    const [rows, setRows] = React.useState([]);
    const [scrollRowIdx, setScrollRowIdx] = React.useState(0);

    const gridRef = React.useRef(null);


    React.useEffect(() => {
        function handleClickOutside(e) {
            e.preventDefault();
            const ev = e.target;
            if (gridRef.current.element.contains(ev)) {
                if (!ev.parentNode) return;
                if (!gridRef.current.element.contains(ev.parentNode)) {
                    setSelectedGridRows(null)
                }
            }
        }

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };
    }, []);

    React.useEffect(() => {
        const filter = props.filter
        if (filter) {
            const filteredRows = []
            for (const row of props.rows) {
                if (filterObject(row, filter))
                    filteredRows.push(row)
            }

            setRows(filteredRows)
        } else {
            setRows([...props.rows])
        }
    }, [props.rows, props.filter]);

    React.useEffect(() => {
        setSelectedGridRows(new Set([gridSelectedRowId]));
    }, [gridSelectedRowId]);

    React.useEffect(() => {
        if (gridRef?.current?.scrollToRow)
            gridRef.current.scrollToRow(scrollRowIdx);
        if (gridRef?.current?.scrollToCell)
            gridRef.current.scrollToCell({ rowIdx: scrollRowIdx });
    }, [scrollRowIdx]);


    const scrollToRow = (rowIdx) => {
        setScrollRowIdx(rowIdx)
    }

    useImperativeHandle(ref, () => ({
        scrollToRow: scrollToRow,
    }));

    const mobile = isMobile;
    const gridRowHeight = mobile ? 15 : 20;

    return (
        <div style={{ width: "100%", display: "flex", position: "absolute", height: "100%" }}>
            <EventsDataGridRowContext.Provider value={{ filter: props.filter }}>
                <DataGrid
                    ref={gridRef}
                    defaultColumnOptions={{
                        sortable: false,
                        resizable: isMobile ? false : true,
                        //resizable: true,
                        minWidth: isMobile ? 75 : 100,
                        maxWidth: 220
                    }}
                    rowHeight={gridRowHeight}
                    columns={columns}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    className='rdg-light'
                    onSelectedRowsChange={setSelectedGridRows}
                    selectedRows={selectedGridRows}
                    onRowsChange={setRows}
                    renderers={{ renderRow: RowRenderer }}
                    enableVirtualization={true}
                    style={{ width: "100%", height: "100%", fontSize: appTheme.eventGridFontSize }}
                    onCellClick={(args, event) => {
                        event.preventDefault()
                        const rowId = args.row.id;
                        var set = new Set([rowId]);
                        setSelectedGridRows(set);
                        setMakerSelectedId(rowId);
                        if (props.onRowSelection) {
                            props.onRowSelection(rowId);
                        }
                    }}
                />
            </EventsDataGridRowContext.Provider>
            {
                props.message &&
                <div
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "orangered",
                        whiteSpace: "pre-line",
                        fontSize: appTheme.eventGridErrorFontSize
                        //fontWeight: "bold"
                    }}>
                    {props.message}
                </div>
            }
        </div>
    );
}

export default forwardRef(EventsGridComponent);