import React from 'react'
import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';
import { FaMapLocation } from "react-icons/fa6";

const awesomeSize = isMobile ? 12 : 16;

function MapIconResizer(props) {
    const [open, setOpen] = React.useState(false);
    const [overringActivator, setOverringActivator] = React.useState(false);
    const [leftOffset, setLeftOffset] = React.useState(0);
    const [bottomOffset, setBottomOffset] = React.useState(0);
    const dropMenuRef = React.useRef()
    const activatorRef = React.useRef()

    return (
        <div style={{ justifyItems: "center", display: "flex", flexDirection: "column" }}>
            {
                open &&
                <MapIconResizerDropMenu
                    routeCount={props.routeCount}
                    left={leftOffset}
                    bottom={bottomOffset}
                    ref={dropMenuRef}
                    onLoad={() => {
                        const dropMenuBox = dropMenuRef?.current?.getBoundingClientRect()
                        const activatorBox = activatorRef?.current?.getBoundingClientRect();
                        setLeftOffset(activatorBox.left - dropMenuBox.width / 2 + activatorBox.width / 2)
                        setBottomOffset(window.innerHeight - activatorBox.bottom + activatorBox.height)
                    }}
                    onChange={(e) => {
                        if (props.onChange)
                            props.onChange(e)
                    }}
                    onMouseClickOutside={() => {
                        setOpen(false)
                    }}
                    initialMarkerSize={props.initialMarkerSize}>
                    initialRouteOnly={props.initialRouteOnly}
                </MapIconResizerDropMenu>
            }
            <button
                style={{ backgroundColor: "transparent" }}
                ref={activatorRef}
                onMouseLeave={() => {
                    setOverringActivator(false);
                }}
                onMouseEnter={() => {
                    setOverringActivator(true);
                }}
                onClick={() => {
                    setOpen(!open)
                }}>
                <IconContext.Provider value={{ size: awesomeSize, title: "Icon Display" }}>
                    <FaMapLocation />
                </IconContext.Provider>
            </button>
        </div>
    );
}

const MapIconResizerDropMenu = React.forwardRef(function (props, ref) {
    const [ready, setReady] = React.useState(false);
    const [formData, setFormData] = React.useState({});
    const mouseIn = React.useRef(false);

    React.useEffect(() => {
        const onMouseDown = (e) => {
            if (!mouseIn.current) {
                if (props.onMouseClickOutside)
                    props.onMouseClickOutside()
            }
        }

        const onFocus = () => {

            console.log("Lopalo focus ...")
        }

        const onBlur = () => {
            if (props.onMouseClickOutside)
                props.onMouseClickOutside()
        }

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        window.addEventListener('mousedown', onMouseDown);
        return () => {
            window.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener("blur", onBlur);
            window.removeEventListener("focus", onFocus);
        }
    }, []);

    React.useEffect(() => {
        setFormData({
            markerSize: props.initialMarkerSize,
            routeOnly: props.initialRouteOnly,
            updated: false
        })
        setReady(true)
    }, []);

    React.useEffect(() => {
        if (ready) {
            if (props.onLoad)
                props.onLoad()
        }
    }, [ready]);

    React.useEffect(() => {
        const data = formData
        if (data?.updated) {
            if (props.onChange)
                props.onChange(data)
        }
    }, [formData]);

    if (!ready) return null
    return (
        <div
            ref={ref}
            onMouseLeave={() => {
                mouseIn.current = false;
            }}
            onMouseEnter={() => {
                mouseIn.current = true;
            }}
            style={{
                position: "absolute", bottom: `${props.bottom}px`,
                borderRadius: "4px",
                borderWidth: "2px", borderStyle: "solid",
                backgroundColor: "white", left: `${props.left}px`,
                display: "flex", flexDirection: "column",

                listStyleType: "none", padding: "10px 10px", zIndex: 1
            }}>
            <div style={{ display: "flex", margin: "5px 0px", justifyContent: "space-between" }}>
                <label style={{ display: "block", textAlign: "left" }} htmlFor="show_map_marker">Show markers</label>
                <input
                    type="checkbox"
                    id="show_map_marker"
                    checked={formData.routeOnly}
                    onChange={(e) => {
                        const data = { ...formData }
                        data.routeOnly = e.target.checked
                        data.updated = true
                        setFormData(data)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", justifyContent: "space-between" }}>
                <label style={{ display: "block", textAlign: "left" }} htmlFor="map_marker_custom_size_slider">
                    Size ({formData.markerSize})
                </label>
                <input type="range"
                    min={15}
                    max={35}
                    value={formData.markerSize}
                    class="slider"
                    id="map_marker_custom_size_slider"
                    onChange={(e) => {
                        const data = { ...formData }
                        data.markerSize = parseInt(e.target.value)
                        data.updated = true
                        setFormData(data)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", justifyContent: "space-between" }}>
                <button onClick={() => {
                    const data = { ...formData }
                    data.markerSize = 15
                    data.updated = true
                    setFormData(data)
                }}>Small</button>
                <button onClick={() => {
                    setFormData({
                        ...formData,
                        markerSize: 25,
                        updated: true
                    })
                }}>Normal</button>
                <button onClick={() => {
                    setFormData({
                        ...formData,
                        markerSize: 35,
                        updated: true
                    })
                }}>Large</button>
            </div>
        </div>
    );
})

export default MapIconResizer;